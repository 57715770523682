img {
  max-width: 100%;
  height: auto;
}
.section-headline h2 {
  display: inline-block;
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 70px;
  position: relative;
  text-transform: capitalize;
}

.section-headline h2::after {
  border: 1px solid #333;
  bottom: -20px;
  content: '';
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
  width: 40%;
}

.mw-180px {
  max-width: 180px !important;
}
.w-180px {
  width: 180px;
}

.ng-select.ng-select-single .ng-select-container {
  height: 43px !important;
}
.ng-select .ng-select-container {
  border: 1px solid var(--bs-gray-300) !important;
}

@media (max-width: 767px) {
  .slider-area {
    margin-top: 60px;
  }

  .slider-content {
    padding: 80px 0;
    display: none !important;
  }

  .slider-content h2 {
    line-height: 35px !important;
  }

  .slider-content h1 {
    line-height: 45px !important;
  }

  .layer-1-3 a.ready-btn {
    padding: 8px 15px;
  }

  .section-headline h2 {
    font-size: 30px;
  }

  .well-middle .single-well {
    margin-top: 30px;
  }

  .single-skill {
    margin-bottom: 40px;
  }

  .tab-menu {
    margin-top: 30px;
  }

  .tab-menu ul.nav li a {
    padding: 8px 6px;
  }

  .wellcome-text {
    margin: 0;
    padding: 70px 0;
  }

  .subs-feilds {
    width: 100%;
  }

  .suscribe-input input {
    width: 60%;
  }

  .suscribe-input button {
    font-size: 15px;
    padding: 14px 10px;
    width: 40%;
  }

  .section-headline h3 {
    font-size: 25px;
  }

  .well-text > h2 {
    font-size: 18px;
  }

  .well-text p {
    display: none;
  }

  .single-team-member {
    margin-bottom: 30px;
  }

  .service-right {
    width: 100%;
  }

  .service-images:hover .overly-text {
    display: none;
  }

  .portfolio-area {
    padding-top: 0;
  }

  .project-menu li a {
    padding: 8px 12px;
    margin: 10px 4px;
  }

  .pri_table_list {
    margin-bottom: 30px;
  }

  .single-awesome-project,
  .portfolio-2 .single-awesome-project {
    width: 100%;
    float: none;
  }

  .single-blog {
    margin-bottom: 30px;
  }

  .sus-btn {
    margin-left: 0;
    margin-top: 30px;
  }

  .contact-form {
    margin-top: 30px;
  }

  .head-team h5 {
    font-size: 22px;
  }

  .header-bottom h1 {
    font-size: 30px;
    margin-bottom: 0;
  }

  .search-option input {
    width: 74%;
  }

  .header-bottom h2 {
    font-size: 20px;
    margin-bottom: 0;
  }

  li.threaded-comments {
    margin-left: 0;
  }
}
.mat-mdc-paginator {
  background-color: #f5f8fa !important;
}

.blog-area {
  height: auto;
  width: 100%;
}
.blog-text h4 {
  margin: 0 !important;
}

.blog-text h4 a {
  color: #222;
  text-decoration: none;
}

.blog-text p {
  text-align: justify;
}

.blog-btn {
  border-bottom: 1px dotted #444;
  color: #222;
  text-decoration: none;
}

.blog-btn {
  border-bottom: 1px dotted #444;
  color: #222;
  display: inline-block;
  padding: 0 1px 5px 0;
  position: relative;
  text-decoration: none;
}

.blog-btn {
  position: relative;
}

.blog-btn::after {
  content: '\f178';
  font-family: fontawesome;
  position: absolute;
  right: -20px;
  top: 1px;
  transition: all 0.3s ease 0s;
}

.blog-btn:hover::after {
  right: -30px;
}

.blog-btn:hover {
  color: #222;
  text-decoration: none;
}

.blog_meta span.date_type i {
  margin-left: 5px;
}

.blog-meta span.comments-type {
  margin-left: 5px;
}

.blog-meta span i {
  padding-right: 10px;
}

.blog-content .blog-meta {
  border-bottom: 1px dotted #333;
}

.blog-meta {
  border-bottom: 1px dotted #fff;
  padding: 10px 0;
}

.comments-type > a,
.date-type,
.blog-meta span.comments-type {
  color: #222;
  letter-spacing: 1px;
  margin-right: 5px;
}

.blog-meta .comments-type i {
  padding-right: 0 !important;
}

.blog-content-right .comments-type > a,
.blog-content-right .date-type,
.blog-content-right .blog-meta span.comments-type,
.blog-content-right .blog-text p {
  color: #fff;
  letter-spacing: 1px;
}

.single-blog .ready-btn {
  border: 1px solid #444;
  border-radius: 30px;
  color: #222;
  cursor: pointer;
  display: inline-block;
  font-size: 15px;
  font-weight: 500;
  margin-top: 10px;
  padding: 10px 20px;
  text-align: center;
  transition: all 0.4s ease 0s;
}

.single-blog .ready-btn:hover {
  border: 1px solid #297f3e;
  background-color: #297f3e;
  color: #fff;
}

.single-blog-img:hover img {
  transform: scale(1.1);
}

.hero {
  width: 100%;
  background-size: cover;
  position: relative;
  padding: 170px 0 100px 0;
}
.hero:before {
  content: '';
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}
.hero .hero-content {
  margin: 0 0 30px 0;
}
.hero h1 {
  margin: 0;
  font-size: 46px;
  font-weight: 700;
  line-height: 64px;
  color: #fff;
  font-family: 'Poppins', sans-serif;
  letter-spacing: 2px;
}
.hero h1 span {
  color: #ffc451;
}
.hero h2 {
  color: rgba(255, 255, 255, 0.9);
  margin: 10px 0 20px 0;
  font-size: 37px;
  letter-spacing: 2px;
}
.hero h4 {
  color: rgba(255, 255, 255, 0.9);
  margin: 10px 0 0 0;
  font-size: 16px;
  font-family: 'Open Sans', sans-serif;
  text-align: left;
}
.hero .icon-box {
  padding: 30px 20px;
  transition: ease-in-out 0.3s;
  border: 1px solid rgba(255, 255, 255, 0.3);
  height: 100%;
  text-align: center;
}
.hero .icon-box i {
  font-size: 32px;
  line-height: 1;
  color: #ffc451;
}
.hero .icon-box h3 {
  font-weight: 700;
  margin: 10px 0 0 0;
  padding: 0;
  line-height: 1;
  font-size: 20px;
}
.hero .icon-box h3 a {
  color: #fff;
  transition: ease-in-out 0.3s;
}
.hero .icon-box h3 a:hover {
  color: #ffc451;
}
.hero .icon-box:hover {
  border-color: #ffc451;
}

@media (min-width: 1024px) {
  .hero {
    background-attachment: fixed;
  }
}
@media (max-width: 960px) {
  .hero h1 {
    font-size: 48px;
  }
}
@media (max-width: 720px) {
  .hero {
    height: auto;
  }
  .hero h1 {
    font-size: 20px;
    line-height: 0;
    letter-spacing: 0;
  }
  .hero h2 {
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0;
  }
  .hero h3 {
    font-size: 16px;
  }
}
@media (max-width: 350px) {
  .hero h1 {
    font-size: 17px;
    line-height: 0;
    letter-spacing: 0;
  }
}

.btn-track {
  background: #fff100;
  height: 100%;
}

.btn-track:hover {
  background: #ffcc00;
}

#about {
  padding: 40px 0;
}

#about p {
  line-height: 1.8;
  text-align: justify;
}
#about img {
  margin: 20px 0;
}
#about ul {
  text-align: left;
  line-height: 1.8;
  margin: 0 18px 15px;
  list-style-type: inherit;
}
#about h3 {
  font-weight: 600;
  margin-bottom: 15px;
}
#about h5 {
  font-weight: 600;
  text-align: left;
  font-size: 17px;
}
#about .about-content {
  margin-bottom: 20px;
}
#about .about-content .row img {
  width: 100px;
  height: 100px;
}
#about .about-content .row p {
  text-align: left;
}
#about img {
  width: 500px;
  height: auto;
}
#about span {
  text-align: justify;
  letter-spacing: 0;
}

.about-area {
  background-color: #f9f9f9;
}

.single-well > a {
  display: block;
}

.single-well ul li {
  color: #222;
  display: block;
  padding: 5px 0;
}

.single-well ul li i {
  color: #3ec1d5;
  margin-right: 5px;
  font-size: 18px;
}

.single-well p {
  color: #222;
}
.w-full {
  width: 100%;
}
.text-red {
  color: red !important;
}
.f-16 {
  font-size: 16px !important;
}
.text-justify {
  text-align: justify;
  line-height: 1.5;
}

@media (max-width: 720px) {
  #about h3 {
    font-size: 24px;
  }
}

.page-area {
  position: relative;
}

.blog-page .banner-box {
  margin-bottom: 40px;
}

.search-option input {
  border: medium none;
  padding: 6px 15px;
  width: 80%;
}

.search-option {
  border: 1px solid #ccc;
  height: 46px;
  margin-bottom: 30px;
}

.search-option button {
  background: transparent none repeat scroll 0 0;
  border: medium none;
  font-size: 20px;
  padding: 8px 23px;
}

.search-option button:hover {
  color: #3ec1d5;
}

.left-blog h4 {
  border-bottom: 1px solid #ddd;
  color: #222;
  font-size: 17px;
  font-weight: 500;
  margin-bottom: 0;
  padding: 15px 10px;
}

.left-blog {
  background: #f9f9f9 none repeat scroll 0 0;
  margin-bottom: 30px;
  overflow: hidden;
  padding-bottom: 20px;
}

.left-blog li {
  border-bottom: 1px solid #ddd;
  display: block;
}

.left-blog ul li a {
  color: #222;
  display: block;
  font-size: 14px;
  padding: 10px;
  text-transform: capitalize;
}

.recent-single-post {
  border-bottom: 1px solid #ddd;
  display: block;
  overflow: hidden;
  padding: 15px 10px;
}

.ready-btn {
  border: 1px solid #fff;
  border-radius: 30px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 17px;
  font-weight: 600;
  margin-top: 30px;
  padding: 12px 40px;
  text-align: center;
  transition: all 0.4s ease 0s;
  z-index: 222;
}

.ready-btn:hover {
  color: #fff;
  background: #3ec1d5;
  border: 1px solid #3ec1d5;
  text-decoration: none;
}

.post-img {
  display: inline-block;
  padding: 0 5px;
  width: 35%;
}

.pst-content {
  display: inline-block;
  width: 65%;
}

.pst-content p a:hover,
.left-blog ul li a:hover {
  color: #3ec1d5;
}

.blog-page .single-blog {
  margin-bottom: 40px;
}

.pst-content p a {
  color: #222;
  font-size: 15px;
}

.header-bottom h1,
.header-bottom h2 {
  color: #fff;
}

.blog-tags {
  padding: 1px 0;
}

.left-blog li:last-child {
  border-bottom: 0;
}

.popular-tag.left-blog ul li a:hover {
  color: #fff;
}

.popular-tag.left-side-tags.left-blog ul {
  padding: 0 10px;
}

.blog-1 .banner-box {
  margin-bottom: 30px;
}

.left-tags .left-side-tags ul li {
  border-bottom: 0;
}

.left-tags .left-side-tags ul li a {
  padding: 3px 10px;
  width: auto;
}

.left-side-tags h4 {
  margin-bottom: 15px;
}

/*--------------------------------------------------------------
# Blog Details
--------------------------------------------------------------*/
.post-information h2 {
  color: #363636;
  font-size: 22px;
  text-transform: uppercase;
}

.post-information {
  padding: 20px 0;
}

.post-information .entry-meta span a {
  color: #222;
  display: inline-block;
  padding: 10px 0;
}

.entry-meta span a:hover {
  color: #3ec1d5;
}

.post-information .entry-meta {
  border-bottom: 1px solid #ccc;
  margin: 20px 0;
}

.post-information .entry-meta span i {
  padding: 0 10px;
}

.entry-content > p {
  color: #222;
}

.entry-meta > span {
  color: #222;
}

.entry-content blockquote {
  background: #fff none repeat scroll 0 0;
  border-left: 5px solid #3ec1d5;
  font-size: 17.5px;
  font-style: italic;
  margin: 0 0 20px 40px;
  padding: 22px 20px;
}

.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background-color: #3ec1d5;
  border-color: #3ec1d5;
  color: #fff;
  cursor: default;
  z-index: 3;
}

.social-sharing {
  background: #fff none repeat scroll 0 0;
  border: 1px solid #ccc;
  display: block;
  margin: 30px 0;
}

.social-sharing > h3 {
  display: inline-block;
  font-size: 18px;
  margin: 0;
  padding: 20px 10px;
}

.sharing-icon {
  display: inline-block;
  padding: 13px 10px;
}

.sharing-icon a {
  border: 1px solid #444;
  color: #222;
  display: block;
  float: left;
  font-size: 18px;
  height: 34px;
  line-height: 30px;
  margin-left: 10px;
  text-align: center;
  width: 34px;
}

.sharing-icon a:hover {
  color: #3ec1d5;
  border: 1px solid #3ec1d5;
}

.single-blog .author-avatar {
  float: left;
  margin-right: 10px;
}

.single-blog .author-description h2 {
  font-size: 18px;
  margin: 0;
  padding: 0 0 5px;
}

.author-info {
  background: #fff none repeat scroll 0 0;
  float: left;
  margin: 30px 0;
  padding: 15px;
  width: 100%;
}

.single-post-comments {
  margin-bottom: 60px;
  max-width: 650px;
}

.comments-heading h3,
h3.comment-reply-title {
  border-bottom: 1px solid #e8e8e9;
  color: #222;
  font-size: 18px;
  margin: 0 0 20px;
  padding: 0 0 5px;
  text-transform: uppercase;
  font-weight: bold;
}

.comments-list ul li {
  margin-bottom: 25px;
}

.comments-list-img {
  float: left;
  margin-right: 15px;
}

.comments-content-wrap {
  color: #42414f;
  font-size: 12px;
  line-height: 1;
  margin: 0 0 15px 80px;
  padding: 10px;
  position: relative;
}

.author-avatar {
  display: inline-block;
  width: 10%;
}

.author-description h2 {
  color: #777;
  font-size: 20px;
  text-transform: uppercase;
}

.author-description h2 a {
  color: #000;
}

.comments-content-wrap span b {
  margin-right: 5px;
}

span.post-time {
  margin-right: 5px;
}

.comments-content-wrap p {
  color: #909295;
  line-height: 18px;
  margin-bottom: 5px;
  margin-top: 15px;
}

li.threaded-comments {
  margin-left: 50px;
}

.comment-respond {
  margin-top: 60px;
}

span.email-notes {
  color: #42414f;
  display: block;
  font-size: 12px;
  margin-bottom: 10px;
}

.comments-content-wrap span a {
  color: #000;
}

.comments-content-wrap span a:hover {
  color: #3ec1d5;
}

.single-blog .blog-pagination {
  border-top: 1px solid #e5e5e5;
  margin: 0;
  padding-top: 30px;
}

.max-w-70px {
  max-width: 70px !important;
}
.max-w-100px {
  max-width: 100px !important;
}
.w-120px {
  width: 120px !important;
}
.w-100px {
  width: 100px !important;
}
.pr-30px {
  padding-right: 30px !important;
}
/*--------------------------------------------------------------
# Recruit
--------------------------------------------------------------*/
.title-recruit {
  font-size: 24px !important;
  color: #242424;
  font-weight: bold !important;
}
.deadline-cv {
  font-size: 17px;
  margin-top: 15px;
  font-weight: bold;
}
.text-day {
  color: #c72d40;
}
.summary-main {
  margin-top: 40px;
  margin-bottom: 50px;
  padding-left: 25px;
  border-left: solid 7px #c23142;
  position: relative;
  font-size: 17px;
}
.category-recruit {
  background-color: #e7eaef;
  padding: 40px 0;
}
.category-recruit a {
  display: inline-block;
  padding: 6px 23px 9px;
  border-radius: 40px;
  margin: 0 3px;
  transition: background 0.2s;
  font-size: 17px;
  font-weight: bold;
}
.btn-category {
  background-color: #fff;
  color: black;
  border-color: #fff;
}
.btn-active {
  background-color: #222;
  color: white;
  border-color: #222;
}
.btn-active .text {
  color: white;
}
.btn-active .number {
  color: #df4053;
}
.blog-page .stt-number {
  color: #d2182f;
  font-size: 46px;
  text-align: center;
  border-bottom: dashed 1px #b7b7b7;
  padding: 20px 0 20px 0;
  font-weight: bold;
}
.blog-page .deadline {
  font-size: 15px;
  margin: 4px 0;
  display: inline-block;
}
.blog-page .day-month {
  display: block;
  font-size: 16px;
  color: #2b2b2b;
  font-weight: bold;
}
.single-recruit p {
  margin: 0 0 5px;
  font-size: 17px;
}
.single-recruit a {
  font-weight: bold;
}
.single-recruit .title {
  font-size: 22px;
  color: black;
}
.single-recruit .content {
  margin-top: 15px;
}
.single-recruit .btn {
  border-radius: 40px;
  color: #fff;
  background-color: #222;
  border-color: #222;
}
/*********************************/
/*         Menu                  */
/*===============================*/
#topnav {
  position: fixed;
  left: 0;
  right: 0;
  z-index: 999;
  border: 0;
  height: 80px;
  transition: all 0.5s;
  background: rgba(0, 0, 0, 0.6);
  -webkit-transition: all 0.5s ease;
}
#topnav .logo {
  float: left;
  width: 130px !important;
  margin: 0;
  padding: 0;
}
#topnav .logo .l-dark,
#topnav .logo .logo-dark-mode {
  display: none;
}
#topnav .logo .l-light,
#topnav .logo .logo-light-mode {
  display: inline-block;
}
#topnav .logo:focus {
  outline: none;
}
#topnav .has-submenu.active a {
  color: #ffffff;
}
#topnav .has-submenu.active .submenu li.active > a {
  color: #fff100 !important;
}
#topnav .has-submenu.active.active .menu-arrow {
  border-color: #fff100;
}
#topnav .has-submenu {
  position: relative;
}
#topnav .has-submenu .submenu {
  position: relative;
}
#topnav .has-submenu .submenu .submenu-arrow {
  border: solid #3c4858;
  border-radius: 0.5px;
  border-width: 0 0 2px 2px;
  display: inline-block;
  padding: 3px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  left: 20px;
  top: 13px;
}
#topnav .has-submenu .submenu .has-submenu .submenu .has-submenu:hover .submenu-arrow {
  border-color: #fff100;
}
#topnav .has-submenu .submenu .has-submenu:hover > .submenu-arrow {
  border-color: #fff100;
}
#topnav .navbar-toggle {
  border: 0;
  position: relative;
  padding: 0;
  margin: 0;
  cursor: pointer;
}
#topnav .navbar-toggle .lines {
  width: 25px;
  display: block;
  position: relative;
  margin: 30px 10px 26px 0;
  height: 18px;
}
#topnav .navbar-toggle span {
  height: 2px;
  width: 100%;
  background-color: #fff;
  display: block;
  margin-bottom: 5px;
  -webkit-transition: -webkit-transform 0.5s ease;
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
}
#topnav .navbar-toggle span:last-child {
  margin-bottom: 0;
}

#topnav .navbar-toggle.open span {
  position: absolute;
}
#topnav .navbar-toggle.open span:first-child {
  top: 6px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
#topnav .navbar-toggle.open span:nth-child(2) {
  visibility: hidden;
}
#topnav .navbar-toggle.open span:last-child {
  width: 100%;
  top: 6px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
#topnav .navbar-toggle.open span:hover {
  background-color: #fff100;
}
#topnav .navbar-toggle:hover,
#topnav .navbar-toggle:focus,
#topnav .navbar-toggle .navigation-menu > li > a:hover,
#topnav .navbar-toggle:focus {
  background-color: transparent;
}
#topnav .navigation-menu {
  list-style: none;
  margin: 0;
  padding: 0;
}
#topnav .navigation-menu > li {
  float: right;
  display: block;
  position: relative;
  margin: 0 10px;
}
#topnav .navigation-menu > li:hover > a,
#topnav .navigation-menu > li.active > a {
  color: #fff100 !important;
}
#topnav .navigation-menu > li > a {
  display: block;
  font-size: 17px;
  font-weight: 500;
  color: #fff;
  background-color: transparent !important;
  letter-spacing: 1px;
  line-height: 24px;
  padding-right: 15px;
  padding-left: 15px;
}
#topnav .navigation-menu > li > a:hover,
#topnav .navigation-menu > li > a:active {
  color: #fff100;
}
#topnav .navigation-menu > li .submenu.megamenu li .megamenu-head {
  padding: 10px 20px;
  white-space: nowrap;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.04em;
  font-weight: 700;
  color: #161c2d !important;
}
#topnav .navigation-menu .has-submenu .menu-arrow {
  border: solid #fff;
  border-radius: 0.5px;
  border-width: 0 0 2px 2px;
  display: inline-block;
  padding: 3px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  position: absolute;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  right: -1px;
  top: 30px;
}
#topnav .navigation-menu .has-submenu:hover .menu-arrow {
  -webkit-transform: rotate(-225deg);
  transform: rotate(-225deg);
}
#topnav .menu-extras {
  float: right;
}
#topnav.scroll {
  background-color: #ffffff;
  border: none;
  -webkit-box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
  box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
}
#topnav.scroll .navigation-menu > li > a {
  color: #fff;
}
#topnav.scroll .navigation-menu > li > .menu-arrow {
  border-color: #fff;
}
#topnav.scroll .navigation-menu > li:hover > a,
#topnav.scroll .navigation-menu > li.active > a {
  color: #fff100;
}
#topnav.scroll .navigation-menu > li:hover > .menu-arrow,
#topnav.scroll .navigation-menu > li.active > .menu-arrow {
  border-color: #fff100;
}
#topnav.defaultscroll.dark-menubar .logo {
  line-height: 70px;
}
#topnav.defaultscroll.scroll .logo {
  line-height: 62px;
}
#topnav.defaultscroll.scroll.dark-menubar .logo {
  line-height: 62px;
}
#topnav.nav-sticky {
  background: rgba(0, 0, 0, 0.8);
  top: 0;
}

#topnav.nav-sticky.tagline-height {
  top: 0 !important;
}
#topnav.nav-sticky .buy-button .login-btn-primary,
#topnav.nav-sticky .buy-button .btn-icon-dark {
  display: inline-block;
}
#topnav.nav-sticky .buy-button .login-btn-light,
#topnav.nav-sticky .buy-button .btn-icon-light {
  display: none;
}
#topnav.nav-sticky .logo .l-dark {
  display: inline-block;
}
#topnav.nav-sticky .logo .l-light {
  display: none;
}

.logo {
  font-weight: 700;
  font-size: 24px;
  margin-left: 15px;
  padding: 0 0 6px;
  letter-spacing: 1px;
  line-height: 68px;
}

@media (min-width: 1025px) {
  #topnav .navigation-menu > li .submenu.megamenu {
    width: 1116px !important;
  }
}
@media screen and (max-width: 1024px) and (min-width: 992px) {
  #topnav .navigation-menu > li .submenu.megamenu {
    width: 936px !important;
  }
}
@media (min-width: 992px) {
  #topnav .navigation-menu {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  #topnav .navigation-menu > .has-submenu:hover .menu-arrow {
    top: 33px !important;
  }
  #topnav .navigation-menu > .has-submenu.active .menu-arrow {
    top: 30px;
  }
  #topnav .navigation-menu > li .submenu {
    position: absolute;
    top: 100%;
    right: 0;
    z-index: 1000;
    padding: 15px 0;
    list-style: none;
    min-width: 180px;
    visibility: hidden;
    opacity: 0;
    margin-top: 10px;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    border-radius: 6px;
    background-color: #fff;
    -webkit-box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
    box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
  }
  #topnav .navigation-menu > li .submenu li {
    position: relative;
  }
  #topnav .navigation-menu > li .submenu li a {
    display: block;
    padding: 10px 20px;
    clear: both;
    white-space: nowrap;
    font-size: 15px;
    letter-spacing: 0.04em;
    font-weight: 500;
    color: #3c4858 !important;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }
  #topnav .navigation-menu > li .submenu li a:hover {
    color: #fff100 !important;
  }
  #topnav .navigation-menu > li .submenu li ul {
    list-style: none;
    padding-right: 0;
    margin: 0;
  }
  #topnav .navigation-menu > li .submenu.megamenu {
    white-space: nowrap;
    right: 50%;
    -webkit-transform: translateX(50%);
    transform: translateX(50%);
    position: fixed;
    top: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  #topnav .navigation-menu > li .submenu.megamenu > li {
    overflow: hidden;
    vertical-align: top;
    width: 20%;
  }
  #topnav .navigation-menu > li .submenu.megamenu > li .submenu {
    right: 100%;
    top: 0;
    margin-right: 10px;
    margin-top: -1px;
  }
  #topnav .navigation-menu > li .submenu > li .submenu {
    right: 101%;
    top: 0;
    margin-right: 10px;
    margin-top: -1px;
  }
  #topnav .navigation-menu > li > a {
    padding-top: 25px;
    padding-bottom: 25px;
    min-height: 62px;
  }

  #topnav .navigation-menu > li:hover > .menu-arrow {
    border-color: #fff100;
  }
  #topnav .navigation-menu > li:hover > a,
  #topnav .navigation-menu > li.active > a {
    color: #fff100 !important;
  }
  #topnav .navigation-menu > li.last-elements .submenu {
    right: auto;
    left: 0;
  }
  #topnav .navigation-menu > li.last-elements .submenu:before {
    right: auto;
    left: 10px;
  }
  #topnav .navigation-menu > li.last-elements .submenu > li.has-submenu .submenu {
    right: auto;
    left: 100%;
    margin-right: 0;
    margin-left: 10px;
  }

  #topnav .navbar-toggle {
    display: none;
  }
  #topnav #navigation {
    display: block !important;
  }
  #topnav.scroll {
    top: 0;
  }
  #topnav.scroll .navigation-menu > li > a {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  #topnav.scroll-active .navigation-menu > li > a {
    padding-top: 25px;
    padding-bottom: 25px;
  }
}
@media (max-width: 991px) {
  #topnav {
    min-height: 74px;
  }
  #topnav .logo .l-dark {
    display: inline-block !important;
  }
  #topnav .logo .l-light {
    display: none !important;
  }
  #topnav .container {
    width: auto;
  }
  #topnav #navigation {
    max-height: 400px;
  }
  #topnav .navigation-menu {
    float: none;
  }
  #topnav .navigation-menu > li {
    float: none;
  }
  #topnav .navigation-menu > li .submenu {
    display: none;
    list-style: none;
    padding-right: 20px;
    margin: 0;
  }
  #topnav .navigation-menu > li .submenu li a {
    display: block;
    position: relative;
    padding: 7px 15px;
    font-size: 15px;
    letter-spacing: 0.04em;
    font-weight: 500;
    color: #3c4858 !important;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }
  #topnav .navigation-menu > li .submenu.megamenu li .megamenu-head {
    padding: 7px 15px;
  }
  #topnav .navigation-menu > li .submenu.open {
    display: block;
  }
  #topnav .navigation-menu > li .submenu .submenu {
    display: none;
    list-style: none;
  }
  #topnav .navigation-menu > li .submenu .submenu.open {
    display: block;
  }
  #topnav .navigation-menu > li .submenu.megamenu > li > ul {
    list-style: none;
    padding-right: 0;
  }
  #topnav .navigation-menu > li .submenu.megamenu > li > ul > li > span {
    display: block;
    position: relative;
    padding: 10px 15px;
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 2px;
    color: #8492a6;
  }
  #topnav .navigation-menu > li > a {
    color: #3c4858;
    padding: 10px 20px;
  }
  #topnav .navigation-menu > li > a:after {
    position: absolute;
    left: 15px;
  }
  #topnav .navigation-menu > li > a:hover,
  #topnav .navigation-menu > li .submenu li a:hover,
  #topnav .navigation-menu > li.has-submenu.open > a {
    color: #fff100;
  }
  #topnav .menu-extras .menu-item {
    border-color: #8492a6;
  }
  #topnav .navbar-header {
    float: right;
  }
  #topnav .buy-button .login-btn-primary,
  #topnav .buy-button .btn-icon-dark {
    display: inline-block !important;
  }
  #topnav .buy-button .login-btn-light,
  #topnav .buy-button .btn-icon-light {
    display: none;
  }

  #topnav .has-submenu .submenu .submenu-arrow {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    position: absolute;
    left: 20px;
    top: 12px;
  }
  #topnav .has-submenu.active a {
    color: #fff100;
  }

  #navigation {
    position: absolute;
    top: 74px;
    right: 0;
    width: 100%;
    display: none;
    height: auto;
    padding-bottom: 0;
    overflow: auto;
    border-top: 1px solid #f2f4f6;
    border-bottom: 1px solid #f2f4f6;
    background-color: #fff;
    -webkit-box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
    box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
  }
  #navigation.open {
    display: block;
    overflow-y: auto;
  }
}
@media (max-width: 768px) {
  #topnav .navigation-menu .has-submenu .menu-arrow {
    display: none;
  }
}
@media (min-width: 768px) {
  #topnav .navigation-menu > li.has-submenu:hover > .submenu {
    visibility: visible;
    opacity: 1;
    margin-top: 0;
  }
  #topnav .navigation-menu > li.has-submenu:hover > .submenu > li.has-submenu:hover > .submenu {
    visibility: visible;
    opacity: 1;
    margin-right: 0;
    margin-left: 0;
  }
  #topnav .navigation-menu > li.has-submenu:hover > .submenu > li.has-submenu:hover > .submenu > li:hover > .submenu {
    visibility: visible;
    opacity: 1;
    margin-right: 0;
    margin-left: 0;
  }

  .navbar-toggle {
    display: block;
  }
}
@media (max-width: 425px) {
  #topnav .buy-menu-btn {
    display: block !important;
    margin: 0 10px;
    padding: 10px 20px;
  }
  #topnav .buy-menu-btn .dropdown .dropdown-menu.show {
    -webkit-transform: translate3d(0px, -54px, 0px) !important;
    transform: translate3d(0px, -54px, 0px) !important;
  }
}
.tagline {
  position: absolute;
  width: 100%;
  z-index: 99;
  font-size: 14px;
  padding: 13px 0;
}
@media screen and (max-width: 575px) {
  .tagline {
    display: none;
  }
}

@media screen and (max-width: 575px) {
  .tagline-height {
    top: 0px !important;
  }
}
@media (min-width: 576px) {
  .tagline-height {
    top: 47px !important;
  }
}

.sidebar-nav > .navbar-item {
  padding: 5px 0;
}
.sidebar-nav > .navbar-item .navbar-link {
  color: #3c4858 !important;
  font-size: 15px;
  font-weight: 600;
}
.sidebar-nav > .navbar-item .navbar-link .navbar-icon {
  font-size: 18px;
  margin-left: 6px;
}
.sidebar-nav > .navbar-item:hover .navbar-link,
.sidebar-nav > .navbar-item.active .navbar-link {
  color: #fff100 !important;
}

#navmenu-nav li.active a {
  color: #fff100 !important;
}
#navmenu-nav li.account-menu.active .navbar-link,
#navmenu-nav li.account-menu:hover .navbar-link {
  color: #ffffff !important;
  background-color: #fff100 !important;
}

.navbar-white-bg {
  background-color: #fff !important;
}
.blog-paginator {
  width: 600px;
}
.img-blog {
  max-width: 600px !important;
  width: 100% !important;
}
@media (max-width: 991px) {
  .img-blog {
    max-width: 380px !important;
  }
}
